@import "src/styles/_variables-mixins.scss";
.preHome {
  display: flex;
  justify-content: space-between;
  position: relative;
  gap: 40px;

  margin-top: 40px !important;
  margin-bottom: 40px !important;

  &__title {
    color: var(--mobility-color-light-50);
    @include responsiveFontSize(64px, 52px, 40px);
    font-weight: 800;
  }
  &__description {
    margin: 24px 0;

    color: var(--mobility-color-light-50);
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;

    padding: 280px 85px;
    min-height: 700px;

    // background-position: center center;
    // background-repeat: no-repeat;
    // background-size: cover;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    // &--for-you {
    //   background-image: url('/img/for-you-cover.jpeg');
    // }
    // &--for-agent {
    //   background-image: url('/img/for-agent-cover.jpeg');
    // }
  }

  &__image--for-you {
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &__image--for-agent {
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  @include mediaQuery($md) {
    flex-direction: column;

    &__card {
      padding: 110px 32px;

      min-height: auto;
      height: 520px;
    }
  }
}
